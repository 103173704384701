import React, { useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import Footer from '../../components/Footer/Footer';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    phone: '',
    email: '',
    address: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
  };

  return (
    <>
      <section className="hero-section" id="home">
        <div className="hero-content">
          <div className="hero-text">
            <h1>Contact</h1>
            <p>Get in touch with us for any inquiries, support, or collaboration opportunities—our team is here to assist and guide you.</p>
          </div>
          <div className="contact-image">
            <img src="/images/contact1.png" alt="Healthcare Solutions" />
          </div>
        </div>
      </section>

      <section className="contact-sections">
        <div className="contact-rows">
          {/* Contact Form */}
          <div className="contact-form-wrappers">
            <form className="contact-forms" onSubmit={handleSubmit}>
              <h3>Please Get In Touch With Us<hr /></h3>
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                placeholder="Enter mobile number"
                value={formData.phone}
                onChange={handleChange}
              />
              <label>Email Id</label>
              <input
                type="email"
                name="email"
                placeholder="Email Id"
                value={formData.email}
                onChange={handleChange}
              />
              <label>Address</label>
              <textarea
                name="address"
                placeholder="Type your address here"
                value={formData.address}
                onChange={handleChange}
              ></textarea>
              <button type="submit" className="submit-btn">Submit</button>
            </form>
          </div>

          {/* Contact Details and Map */}
          <div className="contact-details-wrappers">
            <div className="contact-details">
              <div className="contact-infos">
                <FaPhoneAlt /> <span>+91 85533 42365</span>
              </div>
              <div className="contact-infos">
                <FaEnvelope /> <span>laconicpharmapl@gmail.com</span>
              </div>
              <div className="contact-infos">
                <FaMapMarkerAlt /> <span> No 30/1, 2nd Main Road, Chinnapa garden, Bangalore 560046.</span>
              </div>
            </div>

            <div className="map">
              <div className="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15549.297752734461!2d77.6241141!3d13.0149997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae171de99eca5d%3A0x795ee7f1534eb5cc!2sLaconic%20Pharma%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1727943290695!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                
                <a
                  href="https://www.google.com/maps/dir/?api=1&destination=Balaji+Complex,+2nd+Floor,+No.36,+5th+Main+Rd,+Lingarajapuram,+Bengaluru,+Karnataka+560084"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Directions
                </a>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
